import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { DOCUMENT, isPlatformBrowser, isPlatformServer } from '@angular/common';



@Injectable({
  providedIn: 'root'
})
export class CustomDomainService {
  private isBrowser: boolean;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    @Inject(PLATFORM_ID) private platformId: object
  ) {
    this.isBrowser = isPlatformBrowser(this.platformId);
  }

  isCustomDomain(): boolean {
    let hostname: string;
    if (this.isBrowser) {
      hostname = window.location.hostname;
    } else {
      hostname = this.document.location.hostname;
    }

    if (hostname === 'dev-app.cvideo.no' || hostname === 'nuu-dev.cvideo.no' || hostname === 'dev-app.nuu.no' ||
      hostname === 'dev.nuu.no') {
      return false;
    }

    if (hostname === 'app.cvideo.no' || hostname === 'nuu.cvideo.no' || hostname === 'nuu.no') {
      return false;
    }


    if (hostname === 'localhost') {
      return false;
    }

    return true;
  }

  public getHostname(): string {
    if (this.isBrowser) {
      return window.location.hostname;
    } else {
      return this.document.location.hostname;
    }
  }

}
