import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable, Subject } from 'rxjs';

import { Application, Comment, HistoryRecord, ApplicationHistoryRecord } from '../../../../shared/models/application/application.model';
import { Room } from '../../../../shared/models/room/room.model';
import { User} from '../../../../shared/models/user/user.model';

import { environment } from '../../../../../environments/environment';
import { Cache, StorageService } from '../../../services/storage/storage.service';

export interface CompanyArchiveApplicationsCounters {
  rejected_count: number;
  archived_count: number;
}

@Injectable()
export class NewApplicationService extends Cache {
    public apiUrl = environment.apiUrl;
    public applicationsUrl = this.apiUrl + 'applications/';
    public saveFinished = new Subject<boolean>();

    private updatedDraftApplication: Application; // store data for updated but not saved application while user records video

    constructor(
        private _storageService: StorageService,
        private _http: HttpClient,
    ) {
        super(_storageService);
    }

    setUpdatedDraftApplication(applicationId: number, application: Partial<Application>): void {
      this.updatedDraftApplication = {
        ...this.updatedDraftApplication,
        [applicationId]: application,
      };
    }

    getUpdatedDraftApplication(applicationId: number): Partial<Application> {
      return this.updatedDraftApplication?.[applicationId];
    }

    removeUpdatedDraftApplication(): void {
      this.updatedDraftApplication = null;
    }

    public getApplication(applicationId: number): Observable<any> {
        return this._http.get<Application>(this.applicationsUrl + applicationId + '/');
    }

    public updateApplication(application: Application): Observable<any> {
        return this._http.put<Application>(this.applicationsUrl + application.id + '/', application);
    }


    public patchApplication(application: Partial<Application>): Observable<any> {
        return this._http.patch<Application>(this.applicationsUrl + application.id + '/', application);
    }

    public deleteApplication(applicationId: number): Observable<any> {
        return this._http.delete<any>(this.applicationsUrl + applicationId + '/');
    }

    public sendApplication(id: number, email: string, message: string): Observable<any> {
        const emailsArray = email.split(',').map(email => email.trim());
        return this._http.post<any>(this.applicationsUrl + id + '/pdf-summary/asmail/', { emails: emailsArray, message });
    }

    public deleteComment(applicationId: number, commentId: number): Observable<any> {
        return this._http.delete(this.applicationsUrl + applicationId + '/comments/' + commentId + '/');
    }

    public saveComment(applicationId: number, comment: any): Observable<any> {
        return this._http.post<any>(this.applicationsUrl + applicationId + '/comments/', comment);
    }

    public updateComment(applicationId: number, comment: Partial<Comment>): Observable<Comment> {
      return this._http.patch<Comment>(`${this.applicationsUrl}${applicationId}/comments/${comment.id}/`, comment);
    }

    public getApplicationComments(applicationId): Observable<any> {
        return this._http.get<Comment[]>(this.applicationsUrl + applicationId + '/comments/');
    }

    public getApplicationRoom(applicationId: number): Observable<Room> {
        return this._http.get<Room>(`${this.applicationsUrl}${applicationId}/rooms/`);
    }


    public exportExcel(
        jobIds: string,
        assessments_folder: number,
        sort?: string
    ): Observable<any> {
        return this._http.get<any>(`${this.applicationsUrl}totaloverview/excel/`, {
            params: {
                jobs: jobIds,
                ...(assessments_folder && { assessments_folder: assessments_folder.toString() }),
                ...(sort && { sort_string: sort }),
            }
        });
    }

    getApplicationHistory(
        applicationId: number,
        resultsNum: number = 0
    ): Observable<any> {
        return this._http.get<HistoryRecord[]>(`${this.applicationsUrl}${applicationId}/history/${resultsNum || ''}`);
    }

    deleteApplicationFile(
      applicationId: number,
      fileId: number
    ): Observable<any> {
      return this._http.delete(`${this.applicationsUrl}${applicationId}/files/${fileId}`);
    }

    printApplications(ids: number[], cc_job_id?: string): Observable<any> {
      return this._http.get(`${this.applicationsUrl}actions/`, {
        // responseType: 'arraybuffer',
        params: {
          applications: ids.join(','),
          cc_job_id: cc_job_id ? cc_job_id : ''
        }
      });
    }

    moveApplications(data: {
      applications_ids: number[],
      new_job_id: number,
      new_assessment_folder_id?: number | null,
      run_sf_functionality?: boolean | null
    }): Observable<any> {
      return this._http.patch(`${this.applicationsUrl}actions/`, data);
    }

    getApplicationItemHistory(userId: number): Observable<any> {
      return this._http.get<ApplicationHistoryRecord[]>(`${this.applicationsUrl}history/${userId}/?excluded`);
    }

    getApplicationMentions(applicationId: number): Observable<any> {
      return this._http.get<User[]>(`${this.applicationsUrl}${applicationId}/mentions/`);
    }

    getPdfSummary(uuid: string): Observable<any>{
      return this._http.get<any>(`${this.applicationsUrl}${uuid}/pdf-summary/`, );
    }

    postPdfSummary(uuid: string): Observable<any> {
      return this._http.post<any>(`${this.applicationsUrl}${uuid}/pdf-summary/`, {});
    }

    getArchiveCounters(newSetting?: number): Observable<CompanyArchiveApplicationsCounters | number> {
      let params = new HttpParams();

      if (newSetting !== undefined) {
        params = params.append('new_setting', `${newSetting}`);
      }

      return this._http.get<CompanyArchiveApplicationsCounters | number>(
        `${this.applicationsUrl}archive-counter/`,
        {params}
      );
    }

    /**
     * clear num_of_deleted_applicants in job
     * @param jobId - job to clear applications info for
     */
    public clearNumOfDeletedApplications(jobId: number): Observable<any> {
        return this._http.delete<any>( `${this.applicationsUrl}removed/${jobId}/`);
    }

    public updateSentApplication(applicationId: number, applicationData: Application): Observable<Application> {
        return this._http.patch<Application>(
          `${this.applicationsUrl}${applicationId}/update-sent/`,
          applicationData
        );
    }
}
