import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';

import { Benefit } from '../../../../shared/components/benefits/benefits.component';
import {
  ApplicantFilter,
  Application,
  SyntheticApplication
} from '../../../../shared/models/application/application.model';
import { Job, JobRequirement, PublishJobOptions } from '../../../../shared/models/job/job.model';

import { environment } from '../../../../../environments/environment';
import { Cache, StorageService } from '../../../services/storage/storage.service';
import {
  ButtonInterface, UniversalModalComponent,
  UniversalModalConfig
} from '../../../../shared/components/universal-modal/universal-modal.component';
import { ModalActions } from '../../../actions/modal/modal.action';
import { Store } from '@ngrx/store';
import { IAppState } from '../../../ngrx';
import { DatesService } from '../../../services/dates/dates.service';
import { ToastComponent } from '../../../../ui/toast/toast.component';
import { Qualification } from '../../../../shared/models/qualification/qualification.model';



@Injectable()
export class JobService extends Cache {
    private apiUrl = environment.apiUrl;
    private jobsUrl = this.apiUrl + 'jobs/';

    constructor(
        private store: Store<IAppState>,
        private storageService: StorageService,
        private http: HttpClient,
        private translate: TranslateService,
        private datesService: DatesService
    ) {
        super(storageService);
    }

    public getJob(id: number): Observable<Job> {
        return this.http.get<Job>(this.jobsUrl + id + '/');
    }

    public getJobApplications(jobId: number, filters?: ApplicantFilter): Observable<Application[]> {
        let params = new HttpParams();

        if (filters?.tags?.length) {
          params = params.append('tags', filters.tags.join(','));
        }

        if (filters?.answers?.length) {
          params = params.append('answers', filters.answers.join(','));
        }

        if (filters?.questions?.length) {
          params = params.append('questions', filters.questions.join(','));
        }

        return this.http.get<Application[]>(this.jobsUrl + jobId + '/applications/', { params });
    }

    public createApplication(application): Observable<Application> {
        return this.http.post<Application>(this.jobsUrl + application.job.id + '/applications/', application);
    }

    public createSyntheticApplication(jobId: number, application: SyntheticApplication, departmentsIds?: number[]): Observable<Application> {
        if (departmentsIds) {
          application['departments'] = departmentsIds;
        }
        return this.http.post<Application>(this.jobsUrl + jobId + '/applications/', application);
    }

    public deleteJob(jobId): Observable<any> {
        return this.http.delete(this.jobsUrl + jobId + '/').pipe(
          catchError((err: HttpErrorResponse) => {
            const message: string = err && err.error && err.error.toLowerCase().includes('deadlock') ?
                                    'DEADLOCK_ERROR' : 'DELETE_JOB_ERROR';

            this.store.dispatch(
              new ModalActions.OpenAction({
                cmpType: ToastComponent,
                props: {
                  view: 'error',
                  title: this.translate.instant(message)
                },
              }));

            throw err;
          })
        );
    }

    public updateJob(job): Observable<Job> {
        return this.http.put<Job>(this.jobsUrl + job.id + '/', job);
    }

    public patchJob(jobId: number, job: Partial<Job>): Observable<Job> {
      return this.http.patch<Job>(this.jobsUrl + jobId + '/', job);
    }

    public getJobs(tags: string, limit: string, page: string): Observable<Job[]> {
        return this.http.get<Job[]>(this.jobsUrl + '?tags=' + tags + '&limit=' + limit + '&page=' + page).pipe();
    }

    getJobMailTemplates(jobId: number): Observable<any> {
      return this.http.get(this.jobsUrl + jobId + '/mail-template/');
    }

    createJobMailTemplates(jobId: number, data): Observable<any> {
      return this.http.post(this.jobsUrl + jobId + '/create-mail-template/', data);
    }

    allowUsageOfSpecificTemplates(jobId: number, data: boolean): Observable<any> {
      return this.http.post(this.jobsUrl + jobId + '/mail-template/', data);
    }

    updateJobMailTemplates(jobId: number, newData): Observable<any> {
      return this.http.patch(this.jobsUrl + jobId + '/mail-template/', newData);
    }

    updateJobEmployee(jobId: number, employee_id: number, employeeData): Observable<any> {
      return this.http.patch(this.jobsUrl + jobId + '/employees/' + employee_id, employeeData);
    }

    updateAdvancedJob(jobId: number, advancedData): Observable<any> {
      return this.http.patch(this.jobsUrl + jobId + '/advanced/', advancedData);
    }

    createBenefit(jobId: number): Observable<any> {
      return this.http.post<Benefit>(`${this.jobsUrl}${jobId}/benefits/`, {});
    }

    public deleteBenefit(jobId: number, benefitIds: number[]): any {
      const options = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
        }),
        body: benefitIds,
      };
      return this.http.delete<any>( `${this.jobsUrl}${jobId}/benefits/`, options);
    }

    public getJobDocsName(jobRequirements: JobRequirement[]): string {
      return jobRequirements?.find(r => r.name === 'otherFile')?.custom_name;
    }

    deleteJobApplications(jobId: number, ids: number[]): Observable<any> {
      const body = {
        applications_ids: ids
      };

      return this.http.request('delete', `${this.jobsUrl}${jobId}/applications/actions/`, { body });
    }

  /**
   * determmine what can be published together with job
   * @param valid - object with flags for job, jobtip and finn
   */
  getPublishType(valid: {
      job: boolean,
      jobtip: boolean,
      finn: boolean,
    }): PublishJobOptions {
      if (!valid.job) {
        return PublishJobOptions.PUBLISH_INVALID;
      }

      if (valid.job && valid.jobtip && valid.finn) {
        return PublishJobOptions.PUBLISH_ALL;
      }

      if (valid.job && valid.jobtip && !valid.finn) {
        return PublishJobOptions.PUBLISH_JOB_JOBTIP;
      }

      if (valid.job && !valid.jobtip && valid.finn) {
        return PublishJobOptions.PUBLISH_JOB_FINN;
      }

      if (valid.job) {
        return PublishJobOptions.PUBLISH_JOB;
      }

      return PublishJobOptions.NONE;
    }

  prepareSaveOptions(publishType: any): any {
    let data;

    switch (publishType) {
      case PublishJobOptions.PUBLISH_ALL:
        data = {
          modalName: 'confirmation-publish-all-modal',
          title: 'PUBLISH_OPTIONS.PUBLISH_ALL.TITLE',
          description: 'PUBLISH_OPTIONS.PUBLISH_ALL.DESCRIPTION',
          buttons: [
            {
              id: 'job-finn',
              caption: 'PUBLISH_OPTIONS.PUBLISH_ALL.BUTTONS.JOB_FINN',
              view: 'outlined'
            },
            {
              id: 'job-jobtip',
              caption: 'PUBLISH_OPTIONS.PUBLISH_ALL.BUTTONS.JOB_JOBTIP',
              view: 'outlined'
            },
            {
              id: 'job-finn-jobtip',
              caption: 'PUBLISH_OPTIONS.PUBLISH_ALL.BUTTONS.BOTH',
              view: 'primary'
            }]
        };

        break;

      case PublishJobOptions.PUBLISH_JOB_JOBTIP:
        data = {
          modalName: 'confirmation-publish-jobtip-modal',
          title: 'PUBLISH_OPTIONS.PUBLISH_JOB_JOBTIP.TITLE',
          description: 'PUBLISH_OPTIONS.PUBLISH_JOB_JOBTIP.DESCRIPTION',
          buttons: [
            {
              id: 'job',
              caption: 'PUBLISH_OPTIONS.PUBLISH_JOB_JOBTIP.BUTTONS.JOB',
              view: 'outlined'
            },
            {
              id: 'job-jobtip',
              caption: 'PUBLISH_OPTIONS.PUBLISH_JOB_JOBTIP.BUTTONS.BOTH',
              view: 'primary'
            }
          ]
        };

        break;

      case PublishJobOptions.PUBLISH_JOB_FINN:
        data = {
          modalName: 'confirmation-publish-finn-modal',
          title: 'PUBLISH_OPTIONS.PUBLISH_JOB_FINN.TITLE',
          description: 'PUBLISH_OPTIONS.PUBLISH_JOB_FINN.DESCRIPTION',
          buttons: [
            {
              id: 'job',
              caption: 'PUBLISH_OPTIONS.PUBLISH_JOB_FINN.BUTTONS.JOB',
              view: 'outlined'
            },
            {
              id: 'job-finn',
              caption: 'PUBLISH_OPTIONS.PUBLISH_JOB_FINN.BUTTONS.BOTH',
              view: 'primary'
            }
          ]
        };

        break;
    }

    return data;
  }

  showSaveOptions(data: {
    modalName: string,
    title: string,
    description: string,
    buttons: ButtonInterface[]
  }): void {
    // show modal with save options
    this.store.dispatch(new ModalActions.OpenAction<UniversalModalConfig>({
        cmpType: UniversalModalComponent,
        fixed: true,
        props: {
          id: data.modalName,
          modalName: data.modalName,
          description: data.description,
          title: data.title,
          buttons: data.buttons
        },
      })
    );
  }

  getDefaultExpirationDate(): string {
    const date = this.datesService.now().plus({ weeks: 6 });

    return this.datesService.toFormat(date, 'yyyy-MM-dd');
  }

  /**
   * job question is considered as invalid if:
   * it is multiple choice type and has at least one empty answer
   */
  isJobQuestionsInvalid(questions: Qualification[]): boolean {
    if (!questions || !questions.length) {
      return false;
    }

    // if multiple-choice question has empty answer
    const invalidQuestion = questions.find(q => !q.is_deleted &&
      !q.is_custom && q.answers.find(a => !a.is_deleted && !a.text)
    );

    return !!invalidQuestion;
  }

  removeInvalidJobQuestions(questions: Qualification[]): Qualification[] {
    if (!questions) {
      return;
    }

    return questions.reduce((acc, q) => {
      if (!q.is_deleted && q.text && (q.is_custom || !q.answers.find(a => !a.text))) {
        acc.push(q);
      }
      return acc;
    }, []);
  }
}
