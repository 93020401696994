import { Action } from '@ngrx/store';

import { Job } from '../../shared/models/job/job.model';



export namespace JobActions {
  const CATEGORY = 'Job';

  export interface IJobActions {
    SWITCH_EDIT_MODE: string;
    SAVE: string;
    SAVE_SUCCESS: string;
    SAVE_FAILURE: string;
  }

  export const ActionTypes: IJobActions = {
    SWITCH_EDIT_MODE: `${CATEGORY} Switch Edit Mode`,
    SAVE: `${CATEGORY} Save Job as a Draft`,
    SAVE_SUCCESS: `${CATEGORY} Save Job Success`,
    SAVE_FAILURE: `${CATEGORY} Save Job Failure`
  };

  export class SwitchEditModeAction implements Action {
    type = ActionTypes.SWITCH_EDIT_MODE;

    constructor(public payload?: boolean) { }
  }

  export class SaveSuccessAction implements Action {
    type = ActionTypes.SAVE_SUCCESS;

    constructor() { }
  }

  export type Actions =
    SwitchEditModeAction |
    SaveSuccessAction;
}
