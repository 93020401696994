/**
 * Created by olenordviste on 09.08.2016.
 */
import { inject, PLATFORM_ID } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, CanDeactivateFn, Router, RouterStateSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { combineLatest, EMPTY, Observable, of, throwError } from 'rxjs';
import { catchError, filter, map, switchMap, take } from 'rxjs/operators';
import { getUserRole, USER_ROLE } from './core/helpers/roles';
import { CompanyActions } from './core/modules/company/actions/company.action';
import { ModalActions } from './core/actions/modal/modal.action';
import { CompanyService } from './core/modules/company/services/company.service';
import { JobService } from './core/modules/job/services/job.service';
import { UserService } from './core/modules/user/services/user.service';
import { Job } from './shared/models/job/job.model';
import {
  UniversalModalComponent,
  UniversalModalConfig
} from './shared/components/universal-modal/universal-modal.component';

import { Actions, ofType } from '@ngrx/effects';

import { JobActions } from './job/actions/job.action';
import { CustomDomainService } from './company/services/custom-domain/custom-domain.service';
import { AuthActions } from './auth';
import { NewApplicationService } from './core/modules/application/services/application.service';
import { User } from './shared/models/user/user.model';
import { UserActions } from './core/modules/user/actions/user.action';
import { isPlatformBrowser } from '@angular/common';


/**
 * disable applicant routes for company
 * redirect unauthenticated users to login page
 */
export const applicantAuthGuard: CanActivateFn = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
) => {
  const router = inject(Router);
  // const webHelperService = inject(WebHelperService);
  const userService = inject(UserService);
  const store = inject(Store);
  const platformId = inject(PLATFORM_ID);

  if (isPlatformBrowser(platformId)) {
    return userService.getCurrentUser().pipe(
      take(1),
      map(user => {
        const isEmployee = userService.isUserEmployee(user);

        // if applicant
        if (!isEmployee) {
          return true;
        }

        // if employee
        if (isEmployee) {
          router.navigate(['/404']);
          return false;
        }

        return false;
      }),
      catchError(err => {
        if (err === '401' || err.status === 401) {
          store.dispatch(AuthActions.logout());
          router.navigate(['login'], { queryParams: { returnUrl: state.url } });
        }

        throw new Error('Can not fetch user');
      })
    );
  }
};

export const FormExitGuard: CanDeactivateFn<any> = (
  component
) => {
  const translate = inject(TranslateService);
  const platformId = inject(PLATFORM_ID);

  return new Promise((
    resolve,
  ) => {
    if (component.hasUnsavedData() && isPlatformBrowser(platformId)) {
      if (window.confirm(translate.instant('COMMON.LEAVE_FORM_CONFIRM'))) {
        resolve(true);
      } else {
        resolve(false);
      }
    } else {
      resolve(true);
    }
  });
};


/**
 * disable leaving pages without saving or removing changes
 */
export const PageExitGuard: CanDeactivateFn<any> = (
  component,
  route: ActivatedRouteSnapshot,
) => {
  const actions$ = inject(Actions);
  const store = inject(Store);
  const applicationService = inject(NewApplicationService);

  const data = route.data as any;

  return new Promise((
    resolve,
  ) => {
    if (component.hasUnsavedData()) {
      const modalName = 'page-exit-modal';

      const defaultButtons = [
        {
          id: 'exit',
          caption: 'COMMON.CANCEL_CONFIRM',
          view: 'outlined',
        },
        {
          id: 'save',
          caption: 'COMMON.SAVE_CONFIRM',
          view: 'primary',
        }
      ];

      store.dispatch(
        new ModalActions.OpenAction<UniversalModalConfig>({
          cmpType: UniversalModalComponent,
          fixed: true,
          props: {
            id: modalName,
            modalName,
            title: data?.title || '',
            description: data?.description || 'COMMON.LEAVE_FORM_CONFIRM',
            buttons: data?.buttons || defaultButtons,
            modalClass: 'w-600'
          },
        }));

      store.select(s => s.ui.modal.latestResult).pipe(
        filter(res => !!res && res.modalName === 'page-exit-modal'),
        take(1),
      ).subscribe(res => {
        if (res.buttonId === 'exit') {
          resolve(true);
        } else if (res.buttonId === 'save') {
          switch (component.pageType) {
            case 'companyProfile':
              actions$
                .pipe(
                  ofType(CompanyActions.ActionTypes.UPDATE_LANDING_END, CompanyActions.ActionTypes.CHANGED),
                ).subscribe(() => {
                resolve(true);
              });
              break;

            case 'finnSettings':
              actions$
                .pipe(
                  ofType(CompanyActions.ActionTypes.PATCH_SUCCESS),
                ).subscribe(() => {
                resolve(true);
              });
              break;

            case 'jobCreate':
              actions$
                .pipe(
                  ofType(JobActions.ActionTypes.SAVE_SUCCESS),
                ).subscribe(() => {
                resolve(true);
              });
              break;

            case 'jobEdit':
              actions$
                .pipe(
                  ofType(JobActions.ActionTypes.SAVE_SUCCESS),
                ).subscribe(() => {
                resolve(true);
              });
              break;

            case 'application-form':
              applicationService.saveFinished.asObservable().pipe(
                filter(r => !!r),
                take(1),
              ).subscribe(() => {
                resolve(true);
              });
              break;

            default:
              resolve(false);
          }
        }

        store.dispatch(
          new ModalActions.CloseAction({
            modalName
          })
        );
      });
    } else {
      resolve(true);
    }
  });
};


/**
 * redirect from old URL job pages to the new ones with company_name in URL
 */
export const JobRedirectGuard: CanActivateFn = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
) => {
  const router = inject(Router);
  const jobService = inject(JobService);

  return jobService.getJob(route.params.jobId).pipe(
    take(1),
    map((job: Job) => {

      if (route.url[0].path === 'stilling') {
        router.navigate([job.company.search_name, 'stilling', route.params.jobId]);
      }

      if (route.url[0].path === 'sok-stilling') {
        router.navigate([job.company.search_name, 'sok-stilling', route.params.jobId]);
      }

      return true;
    }),
    catchError(err => {
      router.navigate(['404']);
      return throwError(err);
    }),
  );
};


export const FollowerUnsubscribeRedirectGuard: CanActivateFn = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
) => {
  const router = inject(Router);
  const companyService = inject(CompanyService);

  return companyService.unsubscribeCompanyFollower(route.params.companyId, route.params.email).pipe(
    take(1),
    map(() => {
      return true;
    }),
    catchError(err => {
      router.navigate(['404']);
      return throwError(err);
    }),
  );
};


/**
 * Guard to disable company routes if it's forbidden for a role of the current user.
 * If the user has access and an allowed role, it checks if the user's current company is different from the company in the link.
 * If different, the user's company is updated to the new company.
 * @param {ActivatedRouteSnapshot} route - The current route snapshot.
 * @param {RouterStateSnapshot} state - The current router state snapshot.
 * @returns {Observable<boolean>} - An observable that emits a boolean indicating whether the route can be activated.
 */
export const companyRoleGuard: CanActivateFn = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
): Observable<boolean> => {
  const router = inject(Router);
  const companyService = inject(CompanyService);
  const userService = inject(UserService);
  const store = inject(Store);
  const roles = route.data.roles as Array<string>;
  const languages = route.data.disabled_languages as Array<string>;
  const companyNameWithQueries = state.url.split('/').filter(e => !!e)[0];
  const companyName = companyNameWithQueries.split('?')[0];
  const returnUrl = state.url;
  const platformId = inject(PLATFORM_ID);

  const navigateTo = (url: string, includeReturnUrl: boolean = false) => {
    if (includeReturnUrl) {
      router.navigate([url], { queryParams: { returnUrl } });
    } else {
      router.navigate([url]);
    }

    return false;
  };

  if (isPlatformBrowser(platformId)) {
    return combineLatest([
      userService.getCurrentUser(),
      companyService.getCompanyByName(companyName)
    ]).pipe(
      take(1),
      switchMap(([user, company]) => {
        if (!user || !company) {
          return of(navigateTo('404'));
        }

        return companyService.getCompanyEmployee(company.id, user.id).pipe(
          take(1),
          catchError(() => {
            return of(navigateTo('404'));
          }),
          switchMap(employee => {
            const hasAccess = !!employee && userService.isUserEmployee(user);
            const isAllowedRole = roles ? roles.includes(getUserRole(user)) : true;
            const isAllowedLanguage = languages ? !languages.includes(company.default_language) : true;

            if (hasAccess && isAllowedRole && isAllowedLanguage) {
              if (user.user_profile.company?.id !== company.id) {
                return userService.patchUser({
                  id: user.id,
                  user_profile: { company }
                }).pipe(
                  switchMap((updatedUser: User) => {
                    store.dispatch(new UserActions.ChangedAction({ current: updatedUser }));
                    return of(true);
                  })
                );
              } else {
                return of(true);
              }
            } else {
              return of(navigateTo('404'));
            }
          })
        );
      }),
      catchError(() => {
        store.dispatch(AuthActions.logout());

        return of(navigateTo('/login', true));
      })
    );
  }
};

/**
 * disables routes if it's spectator
 */
export const SpectatorGuard: CanActivateFn = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
) => {
  const router = inject(Router);
  const companyService = inject(CompanyService);
  const userService = inject(UserService);
  const platformId = inject(PLATFORM_ID);

  if (isPlatformBrowser(platformId)) {
    const companyNameWithQueries = state.url.split('/').filter(e => !!e)[0];
    const companyName = companyNameWithQueries.split('?')[0];

    return combineLatest([
      userService.getCurrentUser(),
      companyService.getCompanyByName(companyName)
    ]).pipe(
      take(1),
      map(([u, c]) => {
        if (!(!!c && !!u)) {
          router.navigate(['404']);
        }

        if (getUserRole(u) !== USER_ROLE.SPECTATOR) {
          return true;
        } else {
          router.navigate(['404']);
        }
      }),
      catchError(err => {
        router.navigate(['/']);
        return throwError(err);
      }),
    );
  }
};

/**
 * disable switching between old and advanced company template for new companies
 */
export const CompanyProfileEditGuard: CanActivateFn = (
  next: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
) => {
  const router = inject(Router);
  const companyService = inject(CompanyService);

  const companyNameWithQueries = state.url.split('/').filter(e => !!e)[0];
  const companyName = companyNameWithQueries.split('?')[0];

  return companyService.getCompanyByName(companyName).pipe(
    filter(_ => !!_),
    map(company => {
      if (companyService.hasAdvancedViewOnly(company)) {
        router.navigate(['/', company.search_name]);
        return false;
      }

      return true;
    }),
    catchError(err => {
      router.navigate(['404']);
      return throwError(err);
    }),
  );
};


/**
 * disable routes for companies that don't have subscription anymore
 */
export const companyValidSubscriptionGuard: CanActivateFn = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
) => {
  const router: Router = inject(Router);
  const companyService: CompanyService = inject(CompanyService);
  const customDomainService = inject(CustomDomainService);
  const pageToRedirectIfInvalid = route.data.pageToRedirectIfInvalid || '/authorize/invalid';

  if (customDomainService.isCustomDomain()) {
    const domain = customDomainService.getHostname();

    return companyService.getCompanyByCustomDomain(domain).pipe(
      map(company => {
        if (!company) {
          router.navigate(['404']);
          return false;
        }

        if (!company.has_valid_subscription) {
          router.navigate([pageToRedirectIfInvalid]);
          return false;
        }

        return true;
      }),
      catchError(() => {
        router.navigate(['404']);
        return EMPTY;
      }),
    );
  } else {
    const companyNameWithQueries = state.url.split('/').filter(e => !!e)[0];
    const companyName = companyNameWithQueries.split('?')[0];

    return companyService.getCompanyByName(companyName).pipe(
      take(1),
      map(company => {
        if (!company) {
          router.navigate(['404']);
          return false;
        }

        if (!company.has_valid_subscription) {
          router.navigate([pageToRedirectIfInvalid]);
          return false;
        }

        return true;
      }),
      catchError(() => {
        router.navigate(['404']);
        return EMPTY;
      }),
    );
  }
};

/**
 * disable routes for companies if ther default language is not the same as required
 */
export const LanguageGuard: CanActivateFn = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
) => {
  const router = inject(Router);
  const companyService: CompanyService = inject(CompanyService);
  const companyName: string = state.url.split('/').filter(e => !!e)[0];
  const requiredLanguage = route.data.required_language;

  return companyService.getCompanyByName(companyName).pipe(
    filter(_ => !!_),
    map(company => {
      if (company.default_language !== requiredLanguage) {
        router.navigate(['404']);
        return false;
      }

      return true;
    }),
    catchError(err => {
      router.navigate(['/']);
      return throwError(err);
    }),
  );
};





