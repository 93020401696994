import { FinnContact, FinnIndustry, FinnOccupation, FinnPartnerId } from '../../../core/modules/finn/models/finn.model';
import { GooglePlaceDetail, GooglePrediction } from '../../services/google/places.service';
import { Company, ImageSize } from '../company/company.model';
import { Department } from '../department/department.model';
import { Image } from '../cfile/cfile.model';
import { User } from '../user/user.model';
import { Benefit } from '../../components/benefits/benefits.component';
import { PlaceID } from '../../../job/models/job-templates.model';
import { JobtipAd } from '../../../jobtip/models/jobtip.model';
import { Qualification } from '../qualification/qualification.model';

export interface JobRequirement {
    id: string | number;
    name: string;
    option: 'optional' | 'required' | 'excluded';
    custom_name?: string;
}

export class Finn {
    finn_url: string;
    expired_at: string;
    id: number;
    published: boolean;
    stopped: boolean;
}

export interface DeletedApplication {
    applicant: User;
    assessment_folder: {
        id: number;
        name: string;
    };
    has_deleted: boolean;
}

export interface JobAdvancedFields {
  theme: string;
  apply_button_text: string;
  apply_button_place: string;
  branding_color: string;
  is_add_gradient: boolean;
  benefits_header: string;
  benefits: Benefit[];
  gallery_images: Image[];
  is_add_gallery_bg: boolean;
  schedule: boolean;
  logo_shadow: string;
  logo_size: ImageSize;
  logo_position: number;
}

export class Job {
    public id: number;
    public title: string;
    public header: string;
    public description: string;
    public external: boolean;
    public internal: boolean;
    public company: Company;
    public ad_image: any;
    public ad_image_mobile?: any;
    public expiration_date: string;
    public apply_asap: boolean;
    public requirements: string;
    public tagstring: string;
    public is_published: boolean;
    public hasCase: boolean;
    public requires_video: boolean;
    public num_of_applicants: number;
    public num_of_deleted_applicants: number;
    public employees: User[];
    public departments: Department[];
    public jobrequirements: JobRequirement[];
    public qualifications: Qualification[];
    public place_id: string | GooglePrediction | GooglePlaceDetail;
    public finn: Finn;
    public youtube_video_link: string;
    public ongoing: boolean;
    public unread_applications: number;
    public can_edit: boolean;
    public publicity_modified_at: string;
    public gobi_videos?: any[];
    deleted_applications: DeletedApplication[];
    use_specific_mail_template: boolean;
    job_tab: number;
    theme: string;
    apply_button_text: string;
    apply_button_place: string;
    branding_color: string;
    is_add_gradient: boolean;
    benefits_header: string;
    benefits: Benefit[];
    gallery_images: Image[];
    is_add_gallery_bg: boolean;
    schedule: boolean;
    preview_img_url: string;
    job_folder: number;
    logo_shadow?: string;
    font?: string;
    logo_size: ImageSize;
    logo_position: number;
    finn_partner_id?: FinnPartnerId;
    places_id: PlaceID[];
    unread_notifications: number;
    jobtip_ad?: JobtipAd;
    use_external_apply_link?: boolean;
    external_apply_link?: string;
    display_tags?: boolean;

    constructor(
    ) {

    }
}


export interface FinnJob {
    id: number;
    finn_partner_id?: string;
    partner_id_name?: string;
    partner_id?: string;
    company: Company;
    industries: FinnIndustry[];
    tags: string[];
    occupations: FinnOccupation[];
    job_contacts: FinnContact[];
    application_email: string;
    company_url: string;
    facebook_url: string;
    linkedin_url: string;
    twitter_handle?: string;
    video_url: string;
    ingress: string;
    order_no: string;
    title: string;
    header: string;
    description: string;
    street_address: string;
    zip_code: string;
    country_code: string;
    application_url?: string;
    number_of_positions: number;
    sector: string;
    duration: string;
    stopped: boolean;
    published: boolean;
    finn_url: string;
    invoice_reference: string;
    advert_start_date: string;
    advert_stop_date: string;
    start_date: string;
    expired_at: string;
    expiration_date: string;
    first_publish_by: {
      first_name: string;
      last_name: string;
    };
    statistics_link: string;
    is_expired: boolean;
}

export class JobPaginationData {
  count: number;
  next: string;
  previous: string;
  results: Job[];
}

export enum PublishJobOptions {
  PUBLISH_INVALID = 'PUBLISH_INVALID',
  PUBLISH_ALL = 'PUBLISH_ALL',
  PUBLISH_JOB_JOBTIP = 'PUBLISH_JOB_JOBTIP',
  PUBLISH_JOB_FINN = 'PUBLISH_JOB_FINN',
  PUBLISH_JOB = 'PUBLISH_JOB',
  NONE = 'NONE',
}

export type AdministrateJob = {
  id: number;
  header: string;
  title: string;
};
